import GetUtils from "../utils/utils";
import axios from 'axios';
import apiAddress from './apiAddress';
axios.defaults.timeout = 60 * 1000;
let token = ""
if (GetUtils.getUrlKey('token')) {
  token = GetUtils.getUrlKey('token')
} else {
  if (localStorage.getItem("token")) {
    token = localStorage.getItem("token")
  }
}
localStorage.setItem("token", token)
const retry = 3;
const domain = apiAddress;
// 公共参数 必须带在所有url上
let BaseData = new URLSearchParams({
  // deviceid: UUID,
  deviceid: "dddddddddddddddddd",
  token: token
}).toString()

// 响应拦截器
axios.interceptors.response.use(response => {
  const { data, code } = response;

  if (code != 200)
    if (data.code == 401) {

    } else if (data.code == 999) {
      // token无效

    }
    else if (data.code == 1000) {

    }

  return response.data;

}, error => {
  console.log(error)

  // if(error.response.data) {
  //   return Promise.reject({type: "error", msg: error.response.data})
  // }
  let retryCount = 0;
  // 请求> 设置好的请求次数，不再发送请求， 返回错误信息
  if (retryCount >= retry) {
    if (error.message == 'Network Error') {
      return Promise.reject({ type: "warning", msg: "网络故障，稍后再试" });
    }
    else if (error.message == 'timeout of 30000ms exceeded') {
      return Promise.reject({ type: "warning", msg: "请求超时，稍后再试" });
    }
  }

  if (error.message == 'Network Error') {
    // retryCount += 1;
    // tautology(error);
  }
  if (error.message == 'timeout of 30000ms exceeded') {
    // retryCount += 1;
    // tautology(error);
  }
});

export function get(url, params, header = {}, prefix = true) {
  return new Promise((resolve, reject) => {
    axios.get(prefix ? `${domain}${url}?${BaseData}` : `${url}?${BaseData}`, {
      params: params
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(`${domain}${url}?${BaseData}`, data).then(response => {
      resolve(response);
    }, err => {
      reject(err)
    })
  })
}

// 重新请求
function tautology(err) {
  let back = new Promise(function (resolve) {
    console.log('接口失败重新请求', err.config.url);
    resolve();
  })
  return back.then(function () {
    return axios(err.config);
  })
}
