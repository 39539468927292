import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/reset.css'  // 引入基本样式
// import 'vant/lib/index.css';
import 'vant/lib/index.less';
import 'lib-flexible/flexible'
import store from './store'
import APIS from './http/apis'
import vant from 'vant';
import VueClipboard from 'vue-clipboard2'
// import "swiper/swiper-bundle.min.css";
// import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueBus from 'vue-bus';
import WebIM from 'easemob-websdk' //引入环信
// Vue.use(VueAwesomeSwiper)
Vue.use(VueBus);
Vue.prototype.WebIM = WebIM //环信全局变量
Vue.prototype.$apis = APIS
Vue.config.productionTip = false
Vue.use(vant)
Vue.use(VueClipboard)
// uat环境调试工具
// import VConsole from 'vconsole'
// let environment = process.env.VUE_APP_MODE;
// if(environment != 'production'){
//     const vConsole = new VConsole();
//     vConsole.setSwitchPosition(20, 200);
// }1
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


