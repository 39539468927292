let apiaddress = '/api';

switch (process.env.VUE_APP_MODE) {
  case 'uat':
    apiaddress = 'https://uat_api.5b377ec.com';
    break;
  case 'production':
    apiaddress = 'https://api.azv3ddp.com';
    break;
}

export default apiaddress;
